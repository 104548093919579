<template>
  <div>
    <vertical-info-card :items="items" header="Scraping"></vertical-info-card>
  </div>
</template>

<script>

import VerticalInfoCard from "@/components/VerticalInfoCard";
import {
  mdiCalendarWeek,
  mdiRewind30,
  mdiUpdate
} from '@mdi/js'

export default {
  components: {
    VerticalInfoCard
  },
  props: ['info'],
  computed: {
    items: function () {
      if (this.info == null) {
        return []
      }
      return [
        {
          icon: mdiUpdate,
          title: 'Today',
          subtitle: 'Scraped articles today',
          value: this.info.scraping.today_count,
        },
        {
          icon: mdiCalendarWeek,
          title: 'Last week',
          subtitle: 'Scraped articles last week',
          value: this.info.scraping.week_count,
        },
        {
          icon: mdiRewind30,
          title: 'Last 30 days',
          subtitle: 'Scraped articles in the last 30 days',
          value: this.info.scraping['30d_count'],
        },
      ]
    }
  }
}

</script>
