<template>
  <div>
    <vertical-info-card :items="items" header="Quotes">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="me-3 mt-5"
            color="primary"
            outlined
            v-bind="attrs"
            v-on="on">Clear Quotes
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            Are you sure that you want to clear all quotes?
          </v-card-title>
          <v-card-text>All quotes in the database will be deleted by this action...</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Dismiss
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              :loading="isLoading"
              @click="clear"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </vertical-info-card>
  </div>
</template>

<script>

import VerticalInfoCard from "@/components/VerticalInfoCard";
import {
  mdiCalendarWeek,
  mdiRewind30,
  mdiUpdate,
  mdiFormatQuoteCloseOutline,
  mdiCheckboxMarkedOutline
} from '@mdi/js'
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    VerticalInfoCard
  },
  data() {
    return {
      info: null,
      dialog: false,
      isLoading: false
    }
  },
  computed: {
    items: function () {
      if (this.info == null) {
        return []
      }
      return [
        {
          icon: mdiFormatQuoteCloseOutline,
          title: 'All Quotes',
          subtitle: 'Total amount of quotes from politicians',
          value: this.info.quotes.count,
        },
        {
          icon: mdiCheckboxMarkedOutline,
          title: 'Processed Articles',
          subtitle: 'Articles where quotes were extracted',
          value: this.info.quotes.processed_articles,
        },
        {
          icon: mdiUpdate,
          title: 'Today',
          subtitle: 'Quotes from today',
          value: this.info.quotes.today_count,
        },
        {
          icon: mdiCalendarWeek,
          title: 'Last week',
          subtitle: 'Quotes from last week',
          value: this.info.quotes.week_count,
        },
        {
          icon: mdiRewind30,
          title: 'Last 30 days',
          subtitle: 'Quotes in the last 30 days',
          value: this.info.quotes['30d_count'],
        },
      ]
    }
  },
  mounted() {
    this.fetchInfo()
  },
  methods: {
    fetchInfo() {
      axios.get(`${config.SERVER_URL}/info/quotes`).then((data) => {
        this.info = data.data
      });
    },
    clear() {
      this.isLoading = true
      axios.post(`${config.SERVER_URL}/quotes/clear`).then((data) => {
        this.dialog = false
        this.isLoading = false
      });
    }
  }
}

</script>
