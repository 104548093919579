<template>
  <div>
    <vertical-info-card :items="items" header="Cache">
      <v-btn
        @click="clearCache"
        class="me-3 mt-5"
        color="primary"
        outlined
        :loading="isLoading">Clear Cache
      </v-btn>
    </vertical-info-card>
  </div>
</template>

<script>

import VerticalInfoCard from "@/components/VerticalInfoCard";
import {
  mdiAccountCircle
} from '@mdi/js'
import axios from "axios";
import config from "@/utils/config";
import moment from "moment";

export default {
  components: {
    VerticalInfoCard
  },
  data() {
    return {
      isLoading: false
    }
  },
  props: ['info'],
  computed: {
    items: function () {
      if (this.info == null) {
        return []
      }
      return [
        {
          icon: mdiAccountCircle,
          title: 'Politicians Cached',
          subtitle: '',
          value: this.info.cache.politicians,
        },
      ]
    }
  },
  methods: {
    clearCache() {
      this.isLoading = true
      axios.post(`${config.SERVER_URL}/cache/clear`).then((data) => {
        this.isLoading = false
        this.$emit('update')
      });
    }
  }
}

</script>
