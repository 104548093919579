<template>
  <div>
    <vertical-info-card :items="items" header="Benchmarks">
      <v-btn
        class="me-3 mt-5"
        color="primary"
        :loading="isLoading"
        outlined
        @click="executeBenchmarks()">Execute Benchmarks
      </v-btn>
    </vertical-info-card>
  </div>
</template>

<script>

import VerticalInfoCard from "@/components/VerticalInfoCard";
import {
  mdiClock,
} from '@mdi/js'
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    VerticalInfoCard
  },
  setup() {
    return {
      items: [],
      isLoading: false
    }
  },
  methods: {
    executeBenchmarks() {
      this.isLoading = true;
      axios.get(`${config.SERVER_URL}/benchmarks`).then((data) => {
        let items = data.data.database.map(function (benchmark) {
          return {
            icon: mdiClock,
            title: benchmark.name,
            subtitle: 'MongoDB',
            value: benchmark.runtime,
          }
        });

        items = items.concat(data.data.elasticsearch.map(function (benchmark) {
          return {
            icon: mdiClock,
            title: benchmark.query,
            subtitle: 'Elasticsearch',
            value: "Keyword: " + benchmark.keyword + " Semantic:" + benchmark.semantic,
          }
        }))


        this.$set(this, 'items', items);
        this.$forceUpdate();
        this.isLoading = false;
      });
    }
  }
}

</script>
