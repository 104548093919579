<template>
  <vertical-info-card header="API Information" :items="items">
    <v-btn
      color="primary"
      class="me-2 mt-5"
      outlined
      @click="updateElastic()">Update Elastic
    </v-btn>

    <v-btn
      color="primary"
      outlined
      class="me-2 mt-5"
      @click="updateElasticTexts()">Update Elastic Texts
    </v-btn>
  </vertical-info-card>
</template>

<script>

import VerticalInfoCard from "@/components/VerticalInfoCard";
import {
  mdiInformation,
  mdiGithub,
  mdiFileDocument,
  mdiFileEdit,
  mdiFileFind,
  mdiMessage
} from '@mdi/js'
import axios from "axios";
import config from "@/utils/config";

export default {
  props: ['info'],
  components: {
    VerticalInfoCard
  },
  computed: {
    items() {
      if (this.info == null) {
        return []
      }
      return [
        {
          icon: mdiInformation,
          title: 'API Version',
          subtitle: '',
          value: this.info.version,
        },
        {
          icon: mdiGithub,
          title: 'Commit Hash',
          subtitle: this.info.git.commit,
          value: '',
        },
        {
          icon: mdiMessage,
          title: null,
          subtitle: this.info.git.message,
          value: '',
        },
        {
          icon: mdiFileDocument,
          title: 'Articles',
          subtitle: 'Number of articles in the db',
          value: this.info.database.articles.count,
        },
        {
          icon: mdiFileEdit,
          title: 'Articles',
          subtitle: 'Number of articles in Elastic',
          value: this.info.elasticsearch.articles.count,
        },
        {
          icon: mdiFileEdit,
          title: 'Articles with full text',
          subtitle: 'Number of articles in Elastic with full text',
          value: this.info.elasticsearch.articles.full_text_count,
        }

      ].concat(Object.entries(this.info.elasticsearch.articles.embedding_stores).map(function (val) {
        let key = val[0];
        let details = val[1];

        return {
          icon: mdiFileFind,
          title: 'Articles',
          subtitle: key,
          value: details.embedded,
        }
      }))
    }
  },
  methods: {
    updateElastic() {
      axios.post(`${config.SERVER_URL}/articles/update-elastic`).then((data) => {
        alert("Started elasticsearch job with id " + data.data.job_id);
      });
    },
    updateElasticTexts() {
      axios.post(`${config.SERVER_URL}/articles/update-elastic-texts`).then((data) => {
        console.log(data.data)
        alert("Started elasticsearch job with id " + data.data.job_id);
      });
    },
  }
}

</script>
