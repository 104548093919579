<template>
  <v-card>
    <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">{{ header }}</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <span v-if="!items.length">No items present.</span>
          <v-list v-if="items.length">
            <v-list-item
              v-for="(data,index) in items"
              :key="data.img"
              :class="`d-flex px-0 ${index > 0 ? 'mt-4':''}`"
            >
              <v-icon large class="me-3">{{ data.icon }}</v-icon>

              <div class="d-flex align-center flex-grow-1 flex-nowrap w-full">
                <div class="me-auto">
                  <h4 class="font-weight-semibold">
                    {{ data.title }}
                  </h4>
                  <span class="text-xs wrap-all">{{ data.subtitle }}</span>
                </div>

                <span class="font-weight-bold ps-2">{{ data.value }}</span>
              </div>
            </v-list-item>
          </v-list>
          <div>
            <slot></slot>
          </div>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['header', 'items'],
  setup() {
    return {}
  },
}
</script>

<style>
.wrap-all {
  word-wrap: anywhere;
  overflow-wrap: anywhere;
}
</style>
