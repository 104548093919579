<template>
  <vertical-info-card header="Database" :items="items"></vertical-info-card>
</template>

<script>

import VerticalInfoCard from "@/components/VerticalInfoCard";
import {
  mdiFile,
  mdiStore,
  mdiDatabase
} from '@mdi/js'

function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export default {
  props: ['info'],
  components: {
    VerticalInfoCard
  },
  computed: {
    items() {
      console.log(this.info)
      if (this.info == null) {
        return []
      }
      console.log(this.info.general)
      const databaseInfo = this.info.database.general
      return [
        {
          icon: mdiFile,
          title: 'File System Usage',
          subtitle: '',
          value: Math.round((databaseInfo.fsUsedSize / databaseInfo.fsTotalSize) * 100) + '%',
        },
        {
          icon: mdiDatabase,
          title: 'Data Size',
          subtitle: '',
          value: bytesToSize(databaseInfo.dataSize),
        },
        {
          icon: mdiStore,
          title: 'Storage Size',
          subtitle: '',
          value: bytesToSize(databaseInfo.storageSize),
        },

      ]
    }
  }
}

</script>
