<template>
  <v-row>
    <v-col cols="12" md="4">
      <api-info-card :info="info"></api-info-card>
    </v-col>
    <v-col cols="12" md="4">
      <storage-information :info="info"></storage-information>
      <CacheInformation :info="info" class="mt-3" @update="updateInfoData"></CacheInformation>
      <quote-information class="mt-3"></quote-information>
    </v-col>
    <v-col cols="12" md="4">
      <Benchmarks></Benchmarks>
      <scrape-information :info="scrapeInfo" class="mt-3"></scrape-information>
    </v-col>
  </v-row>
</template>

<script>
import Benchmarks from "@/components/dashboard/Benchmarks";
import StatisticsCardVertical from "@/components/statistics-card/StatisticsCardVertical";
import StorageInformation from "@/views/dashboard/StorageInformation";
import ApiInfoCard from "@/views/dashboard/ApiInfoCard";
import CacheInformation from "@/components/dashboard/CacheInformation";
import ScrapeInformation from "@/components/dashboard/ScrapeInformation";
import QuoteInformation from "@/components/dashboard/QuoteInformation";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    QuoteInformation,
    StatisticsCardVertical,
    ApiInfoCard,
    StorageInformation,
    Benchmarks,
    CacheInformation,
    ScrapeInformation
  },
  setup() {
    return {
      info: null,
      scrapeInfo: null,
    }
  },
  methods: {
    updateInfoData() {
      axios.get(`${config.SERVER_URL}/info`).then((data) => {
        this.info = data.data
      });
    },
    updateScrapeData() {
      axios.get(`${config.SERVER_URL}/info/scraping`).then((data) => {
        this.scrapeInfo = data.data
      });
    }
  },
  mounted() {
    this.updateInfoData()
    this.updateScrapeData()
  }


}
</script>
